import React from 'react';

import styles from './Spacer.module.scss';

interface IProps {
  horizontal?: boolean;
  sizeMobile: string;
  sizeTablet: string;
  sizeDesktop: string;
  top_border?: string;
}

const Spacer: React.FC<IProps> = ({
  sizeMobile = '0',
  sizeTablet = '0',
  sizeDesktop = '0',
  horizontal = false,
  top_border = 'none'
}) => {
  const getSpaceValue = (spacer) => {
    let spaceValue = spacer;

    if (spacer.includes('var') || spacer.includes('px')) {
      spaceValue = spacer;
    } else {
      spaceValue = `${spacer}px`;
    }

    return spaceValue;
  };

  const nodeStyles = ({ spacerSize, top_border }) => {
    const spacerSizeValue = getSpaceValue(spacerSize);
    const styles: React.CSSProperties = horizontal
      ? { height: '100%', width: `${spacerSizeValue}` }
      : { height: `${spacerSizeValue}`, width: '100%' };
    if (top_border) {
      styles.borderTop = top_border;
    }
    return styles;
  };

  return (
    <>
      <div
        style={nodeStyles({ spacerSize: sizeMobile, top_border })}
        className={styles.spacerMobile}
      />
      <div
        style={nodeStyles({ spacerSize: sizeTablet, top_border })}
        className={styles.spacerTablet}
      />
      <div
        style={nodeStyles({ spacerSize: sizeDesktop, top_border })}
        className={styles.spacerDesktop}
      />
    </>
  );
};

export default Spacer;
